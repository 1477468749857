import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Chip, ListItem, ListItemText, Typography } from "@mui/material";

import { useDebounce } from "../../hooks";
import { useMeetingTemplates } from "../../queries";
import { INVITE_STYLE } from "src/meetingTypes/invite/props";
import { MeetingDefinition } from "../../types";
import { useMeetingTypesRepository } from "../../repository";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";

export function MeetingTypeSelectInput({
  meetingType,
  onMeetingTypeSelect,
}: {
  meetingType: MeetingDefinition | null;
  onMeetingTypeSelect: (value: MeetingDefinition | null) => void;
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const { addError } = useGeneralNotifications();
  const { getMeetingTypeById } = useMeetingTypesRepository();

  const query = useDebounce(input, 750);

  const filters = {
    templateName: query,
    // TODO(vovan): we don't support 'link-first' meeting types yet.
    inviteStyle: [
      INVITE_STYLE.CALENDAR_FIRST,
      INVITE_STYLE.CUSTOM_INVITE,
      INVITE_STYLE.WEBINAR,
    ],
  };

  const { data, loading } = useMeetingTemplates(100, 0, filters);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      // disablePortal
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={input}
      onInputChange={(_, value) => {
        setInput(value);
      }}
      onChange={(_, value) => {
        if (value?.id) {
          getMeetingTypeById(value.id)
            .then((meetingTemplate: MeetingDefinition) => {
              onMeetingTypeSelect(meetingTemplate);
            })
            .catch((err) => {
              addError(err.message);
            });
        }
      }}
      value={meetingType}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <ListItem key={option.id} {...props} dense sx={{ width: "100%" }}>
          <ListItemText
            primary={option.name + (!option.active ? " (Inactive)" : "")}
          />
          {option.inviteStyle === "calendar_first" && (
            <Chip
              size="small"
              label={
                <Typography sx={{ fontSize: "small", whiteSpace: "normal" }}>
                  Kronologic Invite
                </Typography>
              }
              sx={{ height: "100%" }}
            />
          )}
          {option.inviteStyle === "custom" && (
            <Chip
              size="small"
              label={
                <Typography sx={{ fontSize: "small", whiteSpace: "normal" }}>
                  Kronologic Email
                </Typography>
              }
              sx={{ height: "100%" }}
            />
          )}
          {option.inviteStyle === "webinar" && (
            <Chip
              size="small"
              label={
                <Typography sx={{ fontSize: "small", whiteSpace: "normal" }}>
                  Kronologic Webinar
                </Typography>
              }
              sx={{ height: "100%" }}
            />
          )}
        </ListItem>
      )}
      getOptionDisabled={(option) => !option.active}
      options={data?.data || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label="Meeting Template"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}
