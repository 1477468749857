import { useHistory } from "react-router-dom";
import PieChartIcon from "@mui/icons-material/PieChart";
import { getUserDetails } from "src/utils/jwtToken";
import { useActingAs } from "../auth";
import { ROLE_LEVELS, supersedes } from "../auth/roles";
import { useMeetingTypesForReps, useNewMeetingsPage } from "../features";
import { MANUAL_LOGOUT_KEY } from "../logout/Logout";
import { SuperAdminRole, SuperAdminRORole } from "../types";
import { GenericNavbar } from "./GenericNavbar";
import {
  Assessment,
  Assistant,
  BarChart,
  Business,
  CalendarMonth,
  CalendarToday,
  CallSplit,
  CoPresent,
  Group,
  Logout,
  Merge,
  PermContactCalendar,
  Settings,
  Storage,
} from "@mui/icons-material";
import { useReportingPage } from "../features/useReportingPage";

export function Navbar() {
  const meetingTypesForReps = useMeetingTypesForReps();
  const newMeetingsPage = useNewMeetingsPage();
  const reportingPage = useReportingPage();

  const loggedInUser = getUserDetails();
  const [actingAs] = useActingAs();

  const history = useHistory();

  const pages = [];

  const user = actingAs || loggedInUser;

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.ORG_ADMIN,
    })
  ) {
    pages.push({
      icon: <Merge />,
      name: "Integrations",
      route: "/integrations",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    })
  ) {
    pages.push({
      icon: <CallSplit />,
      name: "Channels",
      route: "/channels",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    })
  ) {
    pages.push({
      icon: <PermContactCalendar />,
      name: "Contacts",
      route: "/contacts",
    });
  }

  if (
    reportingPage
      ? supersedes({
          has: user?.role,
          required: ROLE_LEVELS.ORG_ADMIN,
        })
      : supersedes({
          has: user?.role,
          required: ROLE_LEVELS.ORG_ADMIN,
        }) &&
        supersedes({
          // Super-admins want to see reporting for other organizations,
          // even if FF flag is not enabled.
          has: loggedInUser?.role,
          required: ROLE_LEVELS.SUPER_ADMIN_RO,
        })
  ) {
    pages.push({
      icon: <PieChartIcon />,
      name: "Reporting",
      route: "/reporting",
    });
  }
  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    }) ||
    meetingTypesForReps
  ) {
    pages.push({
      icon: <CalendarToday />,
      name: "Templates",
      route: "/meeting-templates",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    })
  ) {
    pages.push({
      icon: <Group />,
      name: "Users",
      route: "/users",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.DEFAULT,
    })
  ) {
    pages.push({
      icon: <CalendarMonth />,
      name: "Meetings",
      route: newMeetingsPage ? "/meetings" : "/instances",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.DEFAULT,
    })
  ) {
    pages.push({
      icon: <CoPresent />,
      name: "Events",
      route: "/events",
    });
  }

  const options = [];

  options.push({
    icon: <Assessment />,
    name: "Reports",
    onClick: () => {
      history.push("/reports");
    },
  });

  options.push({
    icon: <Settings />,
    name: "Settings",
    onClick: () => {
      history.push("/settings/app");
    },
  });

  options.push({
    icon: <Logout />,
    name: "Logout",
    onClick: () => {
      localStorage.setItem(MANUAL_LOGOUT_KEY, "true");
      history.push("/logout");
    },
  });

  const additionalOptions = [
    {
      icon: <Group />,
      name: "Switch Users",
      onClick: () => {
        history.push("/admin/users");
      },
    },
    {
      icon: <BarChart />,
      name: "Reporting",
      onClick: () => {
        history.push("/admin/reporting");
      },
    },
    {
      icon: <Storage />,
      name: "Provisioning",
      onClick: () => {
        history.push("/admin/provisioning");
      },
    },
    {
      icon: <Business />,
      name: "Feature Flags",
      onClick: () => {
        history.push("/admin/feature-flags");
      },
    },
    {
      icon: <Assistant />,
      name: "Merlin Chat",
      onClick: () => {
        history.push("/admin/merlin");
      },
    },
  ];

  return (
    <GenericNavbar
      pages={pages}
      options={options}
      additionalOptions={
        [SuperAdminRole, SuperAdminRORole].includes(user?.role || 0)
          ? additionalOptions
          : []
      }
      user={user}
    />
  );
}
