import {
  AccessTime,
  Delete,
  Download,
  Event,
  Group,
  GroupAdd,
  LocalOffer,
  LocationOn,
  MoreHoriz,
  PersonOff,
  Send,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useActingAs } from "src/auth";
import DropdownMenuButton from "src/componentsV2/ButtonDropdown";
import { PageLayout } from "src/componentsV2/PageLayout";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import TeamManagementDialog from "src/componentsV2/dialogs/TeamManagementDialog";
import AccountStatusFilter from "src/componentsV2/filters/AccountStatusFilter";
import CalendarConnectionFilter from "src/componentsV2/filters/CalendarConnectionFilter";
import FilterSearchString from "src/componentsV2/filters/FilterSearchString";
import FilterSelect from "src/componentsV2/filters/FilterSelect";
import RoleFilter from "src/componentsV2/filters/RoleFilter";
import TagFilter from "src/componentsV2/filters/TagFilter";
import TeamFilter from "src/componentsV2/filters/TeamFilter";
import { TableSort } from "src/componentsV2/tables/Table";
import useGeneralNotifications from "src/hooks/useGeneralNotifications";
import {
  useAddUsersToTeams,
  useApplyCalendarToUsers,
  useCreateTeam,
  useDeleteUsers,
  useInviteUser,
  useTagUsers,
  useUpdateSchedulers,
  useUpdateTimezone,
} from "src/mutations";
import useDeactivateUsers from "src/mutations/useDeactivateUsers";
import { getUserDetails } from "src/utils/jwtToken";
import { AvailabilityPreview } from "../../componentsV2/AvailabilityPreview";
import { Pagination } from "../../componentsV2/Pagination";
import {
  AddTeamDialog,
  ApplyCalendarDialog,
  ConfirmationDialog,
  InviteUserDialog,
  UpdateAvailabilityDialog,
  UpdateTimezoneDialog,
} from "../../componentsV2/dialogs";
import { SelectTable } from "../../componentsV2/tables/SelectTable";
import {
  useDebounce,
  useDeleteConfirmationDialog,
  useSnackbar,
} from "../../hooks";
import { useUsersV2 } from "../../queries";
import {
  TokenStatus,
  Role,
  Tag,
  Team,
  UserStatus,
  roleToString,
} from "../../types";
import ErrorPage from "../ErrorPage";
import { WeekScheduleWrapper } from "src/types/Availability";
import {
  INTEGRATION_IMG_SRC,
  INTEGRATION_TYPES_LABELS,
} from "src/integrations/props";
import MeetingLinkConnectionFilter from "src/componentsV2/filters/MeetingLinkConnectionFilter";

export interface UserFilterValues {
  firstName: string;
  lastName: string;
  email: string;
  status: UserStatus[];
  calendarTokenStatus: TokenStatus[];
  meetingLinkTokenStatus: TokenStatus[];
  team: Team[];
  role: Role[];
  tag: Tag[];
  tagFilterSearch: string;
  teamFilterSearch: string;
}

export function Users() {
  const history = useHistory();

  const PAGE_SIZE = 10;
  const [page, setPage] = useState(1);
  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);
  const [showUpdateTimezone, setShowUpdateTimezone] = useState(false);
  const [showUpdateAvailability, setShowUpdateAvailability] = useState(false);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [showTeamManagementDialog, setShowTeamManagementDialog] =
    useState(false);
  const [showApplyCalendarDialog, setShowApplyCalendarDialog] = useState(false);
  const [showTeamCreationDialog, setShowTeamCreationDialog] = useState(false);

  const [selectedIds, setSelected] = useState<number[]>([]);

  const [sort, setSort] = useState<TableSort>({
    columnId: "lastName",
    order: "asc",
  });

  const loggedInUser = getUserDetails();
  const [actingAs] = useActingAs();
  const org = actingAs?.org || loggedInUser?.org.org_key || "";

  const [showDeactivateUsersDialog, setShowDeactivateUsersDialog] =
    useState<boolean>(false);
  const openDeleteConfirmationDialog = useDeleteConfirmationDialog();
  const { addGeneralNotification, addError } = useGeneralNotifications();
  const tagUsers = useTagUsers();
  const updateSchedulers = useUpdateSchedulers();
  const updateTimezone = useUpdateTimezone();
  const deleteUsers = useDeleteUsers();
  const deactivateUsers = useDeactivateUsers();
  const inviteUser = useInviteUser();
  const addUsersToTeam = useAddUsersToTeams();
  const createTeam = useCreateTeam();

  const applyCalendarToUsers = useApplyCalendarToUsers();
  const [openSnackbar] = useSnackbar();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const { setValue, getValues, watch, reset } = useForm<UserFilterValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      status: ["active"],
      calendarTokenStatus: [],
      meetingLinkTokenStatus: [],
      team: [],
      role: [],
      tag: [],
      tagFilterSearch: "",
      teamFilterSearch: "",
    },
  });

  const firstNameDebounced = useDebounce(getValues("firstName"), 750);
  const lastNameDebounced = useDebounce(getValues("lastName"), 750);
  const emailDebounced = useDebounce(getValues("email"), 750);

  const filters = getValues();
  useEffect(() => {
    setPage(1);
  }, [
    firstNameDebounced,
    lastNameDebounced,
    emailDebounced,
    filters.firstName,
    filters.lastName,
    filters.email,
    filters.status,
    filters.calendarTokenStatus,
    filters.meetingLinkTokenStatus,
    filters.team,
    filters.role,
    filters.tag,
  ]);

  const { data, loading, error } = useUsersV2(
    PAGE_SIZE,
    (page - 1) * PAGE_SIZE,
    {
      filters: {
        firstName: firstNameDebounced,
        lastName: lastNameDebounced,
        email: emailDebounced,
        team: getValues("team").map((t) => t.id),
        tag: getValues("tag").map((t) => t.id),
        status: getValues("status"),
        role: getValues("role"),
        calendarTokenStatus: getValues("calendarTokenStatus"),
        meetingLinkTokenStatus: getValues("meetingLinkTokenStatus"),
      },
      sort: {
        field: sort.columnId as
          | "email"
          | "firstName"
          | "lastName"
          | "meetingLink"
          | "role",
        order: sort.order as "asc" | "desc",
      },
    },
  );

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  return (
    <PageLayout
      title="Users"
      actions={
        <>
          <Tooltip title="Manage Tags" arrow>
            <IconButton
              title="Tags"
              disabled={selectedIds.length < 1}
              onClick={() => {
                setShowTagManagementDialog(true);
              }}
            >
              <LocalOffer />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export Users" arrow>
            <IconButton
              onClick={() => {
                history.push(
                  `/reports/export/users?firstName=${getValues(
                    "firstName",
                  )}&lastName=${getValues("lastName")}&email=${getValues(
                    "email",
                  )}&teams=${JSON.stringify(
                    getValues("team"),
                  )}&tags=${JSON.stringify(
                    getValues("tag"),
                  )}&status=${getValues(
                    "status",
                  )}&calendarTokenStatus=${getValues(
                    "calendarTokenStatus",
                  )}&roles=${getValues("role").join(",")}`,
                );
              }}
            >
              <Download />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Users" arrow>
            <IconButton
              disabled={selectedIds.length < 1}
              onClick={() => {
                openDeleteConfirmationDialog(
                  "Are you sure that you want to delete the selected users?",
                  () => {
                    deleteUsers(selectedIds)
                      .then(() => {
                        addGeneralNotification(
                          `Successfully deleted ${selectedIds.length} user${
                            selectedIds.length > 1 ? "s" : ""
                          }`,
                        );
                        setSelected([]);
                      })
                      .catch();
                  },
                );
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <DropdownMenuButton
            button={(props) => (
              <IconButton {...props}>
                <MoreHoriz />
              </IconButton>
            )}
            options={[
              {
                icon: <Send />,
                label: "Invite User",
                onClick: () => {
                  setShowInviteUser(true);
                },
              },
              {
                icon: <Send />,
                label: "Bulk Invite Users",
                onClick: () => {
                  history.push("/reports/import/users");
                },
              },
              {
                icon: <AccessTime />,
                label: "Set Schedule",
                disabled: selectedIds.length < 1,
                onClick: () => {
                  setShowUpdateAvailability(true);
                },
              },
              {
                icon: <LocationOn />,
                label: "Set Timezone",
                disabled: selectedIds.length < 1,
                onClick: () => {
                  setShowUpdateTimezone(true);
                },
              },
              {
                icon: <Group />,
                label: "Create Team",
                onClick: () => {
                  setShowTeamCreationDialog(true);
                },
              },
              {
                icon: <GroupAdd />,
                label: "Add User To Team",
                disabled: selectedIds.length < 1,
                onClick: () => {
                  setShowTeamManagementDialog(true);
                },
              },
              {
                icon: <Event />,
                label: "Apply Calendar to Users",
                disabled: selectedIds.length < 1,
                onClick: () => {
                  setShowApplyCalendarDialog(true);
                },
              },
              {
                icon: <PersonOff />,
                label: "Deactivate Users",
                disabled: selectedIds.length < 1,
                onClick: () => {
                  setShowDeactivateUsersDialog(true);
                },
              },
            ]}
          />
        </>
      }
    >
      <AddTeamDialog
        open={showTeamCreationDialog}
        onClose={() => {
          setShowTeamCreationDialog(false);
        }}
        onSubmit={async ({ name }) => {
          try {
            await createTeam(name);
            setShowTeamCreationDialog(false);
            openSnackbar(`Team ${name} successfully created.`);
          } catch {
            openSnackbar("Failed to create a team.");
          }
        }}
      />
      {showTagManagementDialog && (
        <TagManagementDialog
          label="Bulk Add Tags"
          open
          onClose={() => setShowTagManagementDialog(false)}
          onSubmit={async (tags) => {
            try {
              await tagUsers(
                selectedIds,
                tags.map((t) => t.id),
              );

              openSnackbar(
                `Added ${tags.length} tag${tags.length > 1 ? "s" : ""} to ${
                  selectedIds.length
                } user${selectedIds.length > 1 ? "s" : ""}`,
              );
              setShowTagManagementDialog(false);
            } catch {
              openSnackbar(`Failed to apply tags to user`);
            }
          }}
        />
      )}
      {showUpdateAvailability && (
        <UpdateAvailabilityDialog
          open={showUpdateAvailability}
          onClose={() => setShowUpdateAvailability(false)}
          onSubmit={(updatedSchedule: WeekScheduleWrapper) => {
            updateSchedulers(updatedSchedule.weekSchedule, selectedIds)
              .then(() => {
                addGeneralNotification(
                  `Updated schedule for ${selectedIds.length} user${
                    selectedIds.length > 1 ? "s" : ""
                  }`,
                );
                setShowUpdateAvailability(false);
              })
              .catch(() => {
                addError(`Failed to update user schedules`);
              });
          }}
        />
      )}
      {showUpdateTimezone && (
        <UpdateTimezoneDialog
          open
          onClose={() => setShowUpdateTimezone(false)}
          onSubmit={(v) => {
            updateTimezone(v, selectedIds)
              .then(() => {
                addGeneralNotification(
                  `Updated timezone for ${selectedIds.length} user${
                    selectedIds.length > 1 ? "s" : ""
                  }`,
                );
                setShowUpdateTimezone(false);
              })
              .catch(() => {
                addError(`Failed to update user timezone`);
              });
          }}
        />
      )}
      {showInviteUser && (
        <InviteUserDialog
          open
          onClose={() => setShowInviteUser(false)}
          onSubmit={({ email, role }) =>
            inviteUser(email, role)
              .then(() => {
                addGeneralNotification("Successfully invited user");
                setShowInviteUser(false);
              })
              .catch(() => {
                addError(
                  "Invalid email, please confirm email is correct and invited user is a member of your organization",
                );
              })
          }
        />
      )}
      {showTeamManagementDialog && (
        <TeamManagementDialog
          label="Add Users to Team(s)"
          open
          onClose={() => setShowTeamManagementDialog(false)}
          onSubmit={async (teams) => {
            try {
              addUsersToTeam(
                org,
                selectedIds,
                teams.map((team) => team.id),
              );
              addGeneralNotification("Successfully add users to team");
              setShowTeamManagementDialog(false);
            } catch {
              addError(
                "There was an error when attempting to add users to team",
              );
            }
          }}
        />
      )}
      {showApplyCalendarDialog && (
        <ApplyCalendarDialog
          open
          users={selectedIds}
          onClose={() => setShowApplyCalendarDialog(false)}
          onSubmit={(users, calendars) => {
            applyCalendarToUsers(
              users,
              calendars.map((calendar) => calendar.calendarId),
            )
              .then(() => {
                addGeneralNotification(
                  "Successfully applied calendar to users",
                );
                setShowApplyCalendarDialog(false);
              })
              .catch(() => {
                addError(
                  "There was an error when attempting to apply calendar to users",
                );
              });
          }}
        />
      )}
      <ConfirmationDialog
        open={showDeactivateUsersDialog}
        onClose={() => {
          setShowDeactivateUsersDialog(false);
        }}
        title="Confirm Deactivation"
        message="Are you sure that you want to deactivate selected users?"
        onConfirm={() => {
          deactivateUsers(selectedIds)
            .then(() => {
              addGeneralNotification(
                `Successfully deactivated ${selectedIds.length} user${
                  selectedIds.length > 1 ? "s" : ""
                }`,
              );
              setSelected([]);
            })
            .catch(() => {
              addError(`Failed to deactivate selected users`);
            });
          setShowDeactivateUsersDialog(false);
        }}
      />
      <Stack spacing={2}>
        <Paper elevation={0} sx={{ p: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <FilterSelect<TableSort>
              label="Sort By"
              value={sort}
              isOptionEqualToValue={(option, value) =>
                option.columnId === value?.columnId &&
                option.order === value.order
              }
              onChange={(value) =>
                setSort(
                  value || {
                    columnId: "lastName",
                    order: "asc",
                  },
                )
              }
              options={[
                {
                  label: "First Name (A-Z)",
                  value: { columnId: "firstName", order: "asc" },
                },
                {
                  label: "First Name (Z-A)",
                  value: { columnId: "firstName", order: "desc" },
                },
                {
                  label: "Last Name (A-Z)",
                  value: { columnId: "lastName", order: "asc" },
                },
                {
                  label: "Last Name (Z-A)",
                  value: { columnId: "lastName", order: "desc" },
                },
                {
                  label: "Email (A-Z)",
                  value: { columnId: "email", order: "asc" },
                },
                {
                  label: "Email (Z-A)",
                  value: { columnId: "email", order: "desc" },
                },
              ]}
            />
            <FilterSearchString
              label="First Name"
              value={watch("firstName")}
              onChange={(value) => setValue("firstName", value)}
            />
            <FilterSearchString
              label="Last Name"
              value={watch("lastName")}
              onChange={(value) => setValue("lastName", value)}
            />
            <FilterSearchString
              label="Email"
              value={watch("email")}
              onChange={(value) => setValue("email", value)}
            />
            <RoleFilter
              value={watch("role")}
              onChange={(values) => setValue("role", values)}
            />
            <AccountStatusFilter
              value={watch("status")}
              onChange={(values) => setValue("status", values)}
            />
            <CalendarConnectionFilter
              value={watch("calendarTokenStatus")}
              onChange={(values) => setValue("calendarTokenStatus", values)}
            />
            <MeetingLinkConnectionFilter
              value={watch("meetingLinkTokenStatus")}
              onChange={(values) => setValue("meetingLinkTokenStatus", values)}
            />
            <TeamFilter
              search={watch("teamFilterSearch")}
              onSearchChange={(values) => setValue("teamFilterSearch", values)}
              value={watch("team")}
              onChange={(values) => setValue("team", values)}
            />
            <TagFilter
              search={watch("tagFilterSearch")}
              onSearchChange={(values) => setValue("tagFilterSearch", values)}
              value={watch("tag")}
              onChange={(tags) => setValue("tag", tags)}
            />
            <Divider orientation="vertical" flexItem />
            <Button
              onClick={() => {
                reset();
              }}
            >
              Reset
            </Button>
          </Stack>
        </Paper>

        <Paper elevation={0}>
          <SelectTable
            selected={selectedIds}
            onSelect={(checked, selectedUsers) => {
              if (!checked) {
                setSelected(
                  selectedIds.concat(
                    selectedUsers.map((selectedUser) => selectedUser.id),
                  ),
                );
              } else {
                setSelected(
                  selectedIds.filter(
                    (selectedId) =>
                      !selectedUsers
                        .map((selectedUser) => selectedUser.id)
                        .includes(selectedId),
                  ),
                );
              }
            }}
            loading={loading}
            columns={[
              {
                align: "left",
                component: (user) => (
                  <Link component={RouterLink} to={`/users/${user.id}`}>
                    {user.id}
                  </Link>
                ),
                id: "id",
                label: "Id",
                width: 50,
              },
              {
                id: "name",
                label: "Name",
                component: (user) => (
                  <Stack>
                    <Typography>
                      {user.firstName} {user.lastName}
                    </Typography>
                    <Typography variant="caption">{user.email}</Typography>
                  </Stack>
                ),
                sortable: false,
              },
              {
                id: "role",
                label: "Role",
                component: (user) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={roleToString(user.role)}
                  />
                ),
                visible: sm,
              },
              {
                id: "status",
                label: "Account Status",
                component: (user) => (
                  <Chip
                    variant="outlined"
                    color={
                      user?.status === "active"
                        ? "success"
                        : user?.status === "pending"
                          ? "warning"
                          : user?.status === "deactivated"
                            ? "default"
                            : "error"
                    }
                    size="small"
                    label={user?.status}
                  />
                ),
                visible: md,
              },
              {
                id: "tokenStatus",
                label: "Calendar Connection",
                component: (user) => {
                  const status =
                    user.tokenInfo.tokenType.length < 1
                      ? "Disabled"
                      : user.authValid
                        ? "Current"
                        : "Expired";

                  return (
                    <>
                      {status === "Current" && (
                        <Chip
                          size="small"
                          label="Connected"
                          color="success"
                          variant="outlined"
                        />
                      )}
                      {status === "Disabled" && (
                        <Chip
                          size="small"
                          label="Not Connected"
                          color="default"
                          variant="outlined"
                        />
                      )}
                      {status === "Expired" && (
                        <Chip
                          size="small"
                          label="Expired"
                          color="error"
                          variant="filled"
                        />
                      )}
                    </>
                  );
                },
                visible: md,
              },
              {
                id: "meetingLinkTokenStatus",
                label: "Meeting Link Connection",
                component: (user) => {
                  const status =
                    user.meetingLinkTokenInfo.tokenType.length < 1
                      ? "Disabled"
                      : user.meetingLinkAuthValid
                        ? "Current"
                        : "Expired";

                  return (
                    <Stack direction="row">
                      {status === "Current" && (
                        <Chip
                          size="small"
                          label="Connected"
                          color="success"
                          variant="outlined"
                        />
                      )}
                      {status === "Disabled" && (
                        <Chip
                          size="small"
                          label="Not Connected"
                          color="default"
                          variant="outlined"
                        />
                      )}
                      {status === "Expired" && (
                        <Chip
                          size="small"
                          label="Expired"
                          color="error"
                          variant="filled"
                        />
                      )}
                      {status !== "Disabled" && (
                        <img
                          data-rh={
                            INTEGRATION_TYPES_LABELS[
                              user.meetingLinkTokenInfo.tokenType
                            ]
                          }
                          style={{
                            marginLeft: "5px",
                          }}
                          height={25}
                          width={25}
                          src={
                            INTEGRATION_IMG_SRC[
                              user.meetingLinkTokenInfo.tokenType
                            ]
                          }
                          alt={
                            INTEGRATION_TYPES_LABELS[
                              user.meetingLinkTokenInfo.tokenType
                            ]
                          }
                        />
                      )}
                    </Stack>
                  );
                },
                visible: md,
              },
              {
                id: "availability",
                label: "Availability",
                component: (user) => (
                  <AvailabilityPreview weekSchedule={user.weekSchedule} />
                ),
                width: "145px",
                visible: xl,
              },
              {
                id: "timezone",
                label: "Timezone",
                component: (user) => <>{user.timezone}</>,
                visible: xl,
              },
              {
                id: "calendars",
                label: "Calendars",
                component: (user) => (
                  <Grid container spacing={0.5}>
                    {user.sharedUserCalendars?.map((userCalendar) => (
                      <Grid key={userCalendar.calendarId} item>
                        <Chip
                          size="small"
                          label={userCalendar.calendarName}
                          sx={{ color: "#FFFFFF", backgroundColor: "#c3a7ba" }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ),
                visible: xl,
              },
              {
                id: "teams",
                label: "Teams",
                component: (user) => (
                  <Grid container spacing={0.5}>
                    {user.teams?.map((team) => (
                      <Grid key={team.id} item>
                        <Chip
                          size="small"
                          label={team.name}
                          sx={{ color: "#FFFFFF", backgroundColor: "#81a1d5" }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ),
                visible: lg,
              },
            ]}
            data={data?.data || []}
            minRows={PAGE_SIZE}
            sort={sort}
            onSortChange={setSort}
          />
          <Pagination
            pages={Math.ceil((data?.total || 0) / PAGE_SIZE)}
            currentPage={page}
            onPageChange={setPage}
          />
        </Paper>
      </Stack>
    </PageLayout>
  );
}

export default Users;
