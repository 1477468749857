import React from "react";
import { Box, Container, Link, Paper, Stack, Typography } from "@mui/material";
import MeetingEmptyStateIcon from "src/icons/MeetingEmptyStateIcon";
import { useEvents, WebinarSearchQuery } from "src/queries/useEvents";
import { TableLoading } from "src/componentsV2/tables/Table";
import { SelectTable } from "src/componentsV2/tables/SelectTable";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";

const defaultWebinarQuery: WebinarSearchQuery = {
  filter: {
    hostEmail: "",
    meetingStatusFilter: [],
    meetingIdFilter: [],
  },
  paging: {
    limit: 100,
    offset: 0,
  },
  sort: {
    field: "lastActivity",
    order: "desc",
  },
};

function EventsPage() {
  const { data, loading, error } = useEvents(defaultWebinarQuery);
  const total = data?.total || 0;

  return (
    <Box sx={{ backgroundColor: "#f1f1f1", minHeight: "100%" }}>
      <Container maxWidth="xl" disableGutters sx={{ px: "30px", pb: "65px" }}>
        <Stack
          sx={{
            py: "35px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: "40px", fontWeight: "bold" }}
          >
            Events
          </Typography>
        </Stack>

        <Paper elevation={0}>
          <Box>
            {!loading && total === 0 && (
              <Stack
                sx={{
                  my: "140px",
                  mx: "auto",
                  maxWidth: "800px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  textAlign: "center",
                }}
              >
                <MeetingEmptyStateIcon />
                <Typography
                  color="primary.dark"
                  sx={{ fontSize: "30px", fontWeight: "bold" }}
                >
                  No events found
                </Typography>
              </Stack>
            )}
            {loading && <TableLoading />}
            {!loading && !error && total > 0 && (
              <SelectTable
                loading={loading}
                selected={[]}
                onSelect={console.info}
                data={data?.data || []}
                columns={[
                  {
                    id: "id",
                    label: "Id",
                    component: (row) => (
                      <Link component={RouterLink} to={`/events/${row.id}`}>
                        {row.id}
                      </Link>
                    ),
                  },
                  {
                    id: "meeting_start_time",
                    label: `Time (${dayjs().format("z")})`,
                    component: (row) => {
                      const startTime = row.meeting_start_time
                        ? dayjs(row.meeting_start_time)
                        : null;
                      return (
                        startTime && (
                          <Stack>
                            <Typography variant="caption">
                              {startTime.format("ddd, MMM D")}
                            </Typography>
                            <Typography variant="caption">
                              {startTime.format("h:mm A")}
                            </Typography>
                          </Stack>
                        )
                      );
                    },
                    width: "150px",
                  },
                  {
                    id: "meeting_definition_name",
                    label: "Event Name",
                    component: (row) => (
                      <Stack sx={{ gap: "6px" }}>
                        <Typography fontSize="14px">
                          {row.meeting_definition_name}
                        </Typography>
                      </Stack>
                    ),
                  },
                  {
                    id: "host",
                    label: "Organizer",
                    component: (row) => (
                      <Stack>
                        <Typography variant="caption">
                          {row.host.first_name} {row.host.last_name}
                        </Typography>
                        <Typography variant="caption">
                          {row.host.email}
                        </Typography>
                      </Stack>
                    ),
                  },
                ]}
              />
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default EventsPage;
