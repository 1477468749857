import Mustache from "mustache";
import dayjs from "dayjs";

export interface DynamicVariables {
  guest_account: string;
  guest_first_name: string;
  guest_last_name: string;
  guest_logic_field: string;
  guest_logic_field_2: string;
  guest_phone: string;
  greeting: string;
  meeting_date_time: string;
  meeting_day_of_week: string;
  meeting_link: string;
  meeting_month_day: string;
  meeting_time: string;
  time_casual_day: string;
  time_number_date: string;
  meeting_duration: string;
  host_company: string;
  host_title: string;
  host_first_name: string;
  host_last_name: string;
  host_location: string;
  host_signature: string;
  guest_custom_field_1: string;
  guest_custom_field_2: string;
  guest_custom_field_3: string;
  guest_custom_field_4: string;
  guest_custom_field_5: string;
  guest_custom_field_6: string;
  guest_custom_field_7: string;
  guest_custom_field_8: string;
  guest_custom_field_9: string;
  guest_custom_field_10: string;
}

const sample: DynamicVariables = {
  guest_account: "Space X",
  guest_first_name: "Elon",
  guest_last_name: "Musk",
  guest_logic_field: "Variable Field 1",
  guest_logic_field_2: "Variable Field 2",
  guest_phone: "254 840-5771",
  greeting: "Good evening",
  meeting_date_time: "Tuesday 11/5 @ 2:30 pm CST",
  meeting_day_of_week: "Tuesday",
  meeting_link: "http://url.kronologic.ai",
  meeting_month_day: dayjs().format("MMMM Do"),
  meeting_time: "2:30 pm CST",
  time_casual_day: "In a few days",
  time_number_date: dayjs().format("Do"),
  meeting_duration: "30 minutes",
  host_company: "Kronologic",
  host_title: "Manager",
  host_first_name: "Igor",
  host_last_name: "Lopez",
  host_location: "Austin, TX",
  host_signature: "Igor Lopez",
  guest_custom_field_1: "Contact Custom Field 1",
  guest_custom_field_2: "Contact Custom Field 2",
  guest_custom_field_3: "Contact Custom Field 3",
  guest_custom_field_4: "Contact Custom Field 4",
  guest_custom_field_5: "Contact Custom Field 5",
  guest_custom_field_6: "Contact Custom Field 6",
  guest_custom_field_7: "Contact Custom Field 7",
  guest_custom_field_8: "Contact Custom Field 8",
  guest_custom_field_9: "Contact Custom Field 9",
  guest_custom_field_10: "Contact Custom Field 10",
};

function renderDynamicVariables(
  text: string,
  vars: DynamicVariables = sample,
): string {
  return Mustache.render(text, vars);
}

export default renderDynamicVariables;
