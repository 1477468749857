import useSWR from "swr";

import { useActingAsOverrideHeader } from "src/auth";
import { useTokenRefreshHandler } from "src/hooks";
import { errorHandler } from "src/hooks/errorHandler";
import { useUserService } from "src/services";
import { getUserToken } from "src/utils/jwtToken";

type WebinarStats = {
  total: number;
  accepted: number;
  declined: number;
  no_response: number;
};

type WebinarHostInfo = {
  first_name: string;
  last_name: string;
  email: string;
  user_id: number;
};

type EventItem = {
  id: number;
  status: string;
  meeting_definition_id: number;
  meeting_definition_name: string;
  active: boolean;
  host: WebinarHostInfo;
  meeting_start_time: string;
  created_at: string;
  updated_at: string;
  attendee_stats: WebinarStats;
};

type EventList = {
  total: number;
  data: EventItem[];
};

type WebinarFilter = {
  hostEmail: string;
  meetingStatusFilter: string[];
  meetingIdFilter: number[];
};

export type WebinarSearchQuery = {
  filter: WebinarFilter;
  paging: { limit: number; offset: number };
  sort: { field: "meetingTime" | "lastActivity"; order: "asc" | "desc" };
};

export function useEvents(query: WebinarSearchQuery) {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const accessToken = getUserToken();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR(
    [`/api/webinars/list`, query],
    (url: string, body: object) =>
      service
        .post(url)
        .set(headers)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler),
  );

  return {
    data: data as EventList,
    error,
    loading: !error && !data,
  };
}
