import { Redirect, Route, Switch } from "react-router-dom";

import { RequireAuth, useActingAs } from "../auth";
import { ROLE_LEVELS, supersedes } from "../auth/roles";
import { ActingAsBanner } from "../componentsV2/ActingAsBanner";
import { Navbar } from "../componentsV2/Navbar";
import { PageBanner } from "../componentsV2/PageBanner";
import { RequireRole } from "../componentsV2/RequireRole";
import {
  Features,
  useMeetingTypesForReps,
  useNewMeetingsPage,
  useNewChannelsPage,
} from "../features";
import { useUrlQuery } from "../hooks";
import {
  OrgAdminRole,
  SuperAdminRole,
  SuperAdminRORole,
  TeamAdminRole,
} from "../types";

import ChannelsOld from "../channels/Channels";
import ImportContacts from "../importExport/import/importContacts";
import ImportUsers from "../importExport/import/importUsers";
import { Integrations } from "../integrations/Integrations";
import { Logout } from "../logout/Logout";
import MeetingsPage from "../meetings/MeetingsPage";
import Settings from "./settings";

import { AccountStatusBanner } from "src/componentsV2/AccountStatusBanner";
import ErrorPage from "src/pages/ErrorPage";
import { getUserDetails, setUserToken } from "src/utils/jwtToken";
import Accepted from "./accepted";
import MerlinChat from "./admin/merlin";
import Provisioning from "./admin/provisioning";
import Reporting from "./admin/reporting";
import OrgReport from "./admin/reporting/[org]";
import SwitchUsers from "./admin/users";
import Contact from "./contacts/[id]";
import Contacts from "./contacts/v2";
import Declined from "./declined";
import Error from "./error";
import GuestAction from "./guestAction";
import Login from "./login";
import NewMeetingTemplates from "./meeting-templates";
import MeetingTemplateEditPage from "./meeting-templates/edit/[slug]";
import Meetings from "./meetings";
import Meeting from "./meetings/[id]";
import OneClick from "./oneClick";
import Pending from "./pending";
import Report from "./reports";
import Export from "./reports/export";
import ExportContacts from "./reports/export/contacts";
import ExportMeetings from "./reports/export/meetings";
import ExportUsers from "./reports/export/users";
import Import from "./reports/import";
import FeatureFlags from "./admin/featureFlags";
import Success from "./success";
import Unsubscribe from "./unsubscribe";
import Users from "./users";
import User from "./users/[id]";
import Channels from "./channels/v2";
import Channel from "./channels/[id]";
import CustomerReporting from "./reporting";
import EventsPage from "./events";
import EventDetails from "./events/[id]";

function AuthenticatedRoutes() {
  const [actingAs, setActingAs] = useActingAs();
  const meetingTypesForReps = useMeetingTypesForReps();
  const loggedInUser = getUserDetails();
  const user = actingAs || loggedInUser;

  const newMeetingsPage = useNewMeetingsPage();
  const newChannelsPage = useNewChannelsPage();

  return (
    <>
      <PageBanner mode="sticky">
        {actingAs && (
          <ActingAsBanner
            first={actingAs.firstName}
            last={actingAs.lastName}
            org={actingAs.org}
            role={actingAs.role}
            onClose={() => {
              setActingAs(null);
              window.location.reload();
            }}
          />
        )}
        <div id="kl-profile-menu" />
        <div id="kl-terminal" />
      </PageBanner>
      <AccountStatusBanner />
      <Switch>
        <Route
          path="*"
          render={() => (
            <>
              <Navbar />
              <Switch>
                {newMeetingsPage && (
                  <Route path="/meetings*" render={() => <Settings />}>
                    <Switch>
                      <Route path="/meetings/:id" render={() => <Meeting />} />
                      <Route path="/meetings" render={() => <Meetings />} />
                    </Switch>
                  </Route>
                )}

                <Switch>
                  <Route path="/events/:id" render={() => <EventDetails />} />
                  <Route path="/events" render={() => <EventsPage />} />
                </Switch>
                <Route path="/settings*" render={() => <Settings />} />
                <Route
                  path="/reporting*"
                  render={() => (
                    <RequireRole
                      permitted={[
                        OrgAdminRole,
                        SuperAdminRole,
                        SuperAdminRORole,
                      ]}
                    >
                      <CustomerReporting />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/users*"
                  render={() => (
                    <Switch>
                      <Route
                        path="/users/:id"
                        render={() => (
                          <RequireRole
                            permitted={[
                              TeamAdminRole,
                              OrgAdminRole,
                              SuperAdminRole,
                              SuperAdminRORole,
                            ]}
                          >
                            <User />
                          </RequireRole>
                        )}
                      />
                      <Route
                        path="/users"
                        render={() => (
                          <RequireRole
                            permitted={[
                              TeamAdminRole,
                              OrgAdminRole,
                              SuperAdminRole,
                              SuperAdminRORole,
                            ]}
                          >
                            <Users />
                          </RequireRole>
                        )}
                      />
                    </Switch>
                  )}
                />
                <Route
                  path="/admin/users"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <SwitchUsers />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/reporting/:org"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <OrgReport />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/reporting"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <Reporting />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/provisioning"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <Provisioning />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/feature-flags"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <FeatureFlags />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin/merlin"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <MerlinChat />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/admin"
                  render={() => (
                    <RequireRole permitted={[SuperAdminRole, SuperAdminRORole]}>
                      <Redirect to="/admin/users" />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/integrations"
                  render={() => (
                    <RequireRole
                      permitted={[
                        OrgAdminRole,
                        SuperAdminRole,
                        SuperAdminRORole,
                      ]}
                    >
                      <Integrations />
                    </RequireRole>
                  )}
                />
                <Route
                  path="/reports*"
                  render={() => (
                    <Switch>
                      <Route
                        path="/reports/import/contacts"
                        render={() => <ImportContacts />}
                      />
                      <Route
                        path="/reports/import/users"
                        render={() => <ImportUsers />}
                      />
                      <Route path="/reports/import" render={() => <Import />} />
                      <Route
                        path="/reports/export/contacts"
                        render={() => <ExportContacts />}
                      />
                      <Route
                        path="/reports/export/meetings"
                        render={() => <ExportMeetings />}
                      />
                      <Route
                        path="/reports/export/users"
                        render={() => <ExportUsers />}
                      />
                      <Route path="/reports/export" render={() => <Export />} />
                      <Route path="*" render={() => <Report />} />
                    </Switch>
                  )}
                />
                {(supersedes({
                  has: user?.role,
                  required: ROLE_LEVELS.TEAM_ADMIN,
                }) ||
                  meetingTypesForReps) && (
                  <Route
                    path="/meeting-templates*"
                    render={() => (
                      <Switch>
                        <Route
                          path="/meeting-templates/edit/:id"
                          render={() => <MeetingTemplateEditPage />}
                        />
                        <Route
                          path="/"
                          render={() => <NewMeetingTemplates />}
                        />
                      </Switch>
                    )}
                  />
                )}
                <Route path="/instances/:id" render={() => <MeetingsPage />} />
                <Route
                  path="/instances"
                  render={() => {
                    if (newMeetingsPage) {
                      return <Redirect to="/meetings" />;
                    }

                    return <MeetingsPage />;
                  }}
                />
                {newChannelsPage ? (
                  <Route
                    path="/channels*"
                    render={() => (
                      <Switch>
                        {" "}
                        <Route
                          path="/channels/:id"
                          render={() => (
                            <RequireRole
                              permitted={[
                                TeamAdminRole,
                                OrgAdminRole,
                                SuperAdminRole,
                                SuperAdminRORole,
                              ]}
                            >
                              <Channel />
                            </RequireRole>
                          )}
                        />
                        <Route
                          path="/channels"
                          render={() => (
                            <RequireRole
                              permitted={[
                                TeamAdminRole,
                                OrgAdminRole,
                                SuperAdminRole,
                                SuperAdminRORole,
                              ]}
                            >
                              <Channels />
                            </RequireRole>
                          )}
                        />
                      </Switch>
                    )}
                  />
                ) : (
                  <Route
                    path="/channels"
                    render={() => (
                      <RequireRole
                        permitted={[
                          TeamAdminRole,
                          OrgAdminRole,
                          SuperAdminRole,
                          SuperAdminRORole,
                        ]}
                      >
                        <ChannelsOld />
                      </RequireRole>
                    )}
                  />
                )}
                <Route path="/contacts*">
                  <Switch>
                    <Route
                      path="/contacts/:id"
                      render={() => (
                        <RequireRole
                          permitted={[
                            TeamAdminRole,
                            OrgAdminRole,
                            SuperAdminRole,
                            SuperAdminRORole,
                          ]}
                        >
                          <Contact />
                        </RequireRole>
                      )}
                    />
                    <Route
                      path="/contacts"
                      render={() => (
                        <RequireRole
                          permitted={[
                            TeamAdminRole,
                            OrgAdminRole,
                            SuperAdminRole,
                            SuperAdminRORole,
                          ]}
                        >
                          <Contacts />
                        </RequireRole>
                      )}
                    />
                  </Switch>
                </Route>
              </Switch>
            </>
          )}
        />
        <Route path="*" render={() => <ErrorPage status={404} />} />
      </Switch>
    </>
  );
}

const HomePage = () => {
  const params = useUrlQuery();

  if (params["JWT-TOKEN"]) {
    setUserToken(params["JWT-TOKEN"]);

    if (params.redirectUrl) {
      return <Redirect to={params.redirectUrl} />;
    }
  }

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/instances" />} />
      <Route exact path="/login" render={() => <Login />} />

      <Route exact path="/logout" render={() => <Logout />} />
      <Route path="/1-click/:schedulingToken" render={() => <OneClick />} />
      <Route path="/success" render={() => <Success />} />
      <Route path="/accepted" render={() => <Accepted />} />
      <Route path="/declined" render={() => <Declined />} />
      <Route path="/pending" render={() => <Pending />} />
      <Route path="/error" render={() => <Error />} />
      <Route exact path="/unsubscribe" render={() => <Unsubscribe />} />
      <Route exact path="/guestAction" render={() => <GuestAction />} />
      <Route path="/404" render={() => <ErrorPage status={404} />} />
      <Route
        path="/*"
        render={() => (
          <RequireAuth>
            <Features>
              <AuthenticatedRoutes />
            </Features>
          </RequireAuth>
        )}
      />
      <Route path="*" render={() => <ErrorPage status={404} />} />
    </Switch>
  );
};

export default HomePage;
